<template>
  <div>
    <LayoutHeaderVolunteer />
    <LayoutCookie />
    <LayoutNewVesrion />
    <div class="min-h-[70vh]">
      <SorryMessage />
      <slot />
      <!--      <VErrorBoundary :fall-back="SorryMessage">-->
      <!--        <slot />-->
      <!--      </VErrorBoundary>-->
    </div>
    <LayoutFooter />
    <ClientOnly>
      <TimeoutModal />
    </ClientOnly>
    <LayoutToast />
  </div>
</template>
<script lang="ts" setup>
import SorryMessage from '~/components/layout/SorryMessage.vue';

window.addEventListener('resize', function () {
  if (window.devicePixelRatio < 1) {
    document.body.style.zoom = 1 / window.devicePixelRatio;
  } else {
    document.body.style.zoom = 'normal'; // Reset to normal if zoom is greater than or equal to 100%
  }
});
</script>
